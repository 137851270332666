@import "./shared";

$action-bg: #8c0f61;
.interactive-video-container {
  min-width: 66.5%;
  aspect-ratio: 16 / 9;
  // background: black;
  position: relative;
  overflow: hidden;

  .video-container {
    background: green;
    .poster {
      cursor: pointer;
      width: 100%;
      position: absolute;
      z-index: 1;
      inset: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
    }

    .playing-control-container {
      position: absolute;
      inset: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        transform: scale(0, 0);
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background: $video-control-background;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
          fill: $video-control-svg-color;
        }

        &.animation {
          animation: fadeOut 0.35s ease-in-out 1;
          animation-delay: 0.1s;
        }
      }
    }

    .footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      opacity: 0;
      transition: all 0.5s ease-in-out;
      background: $video-control-background;
      padding: 0 5px;

      div {
        display: flex;
        gap: 0 5px;

        button {
          padding: 5px;
          cursor: pointer;
          background: transparent;
          outline: none;
          border: none;
          color: $video-control-svg-color;
          transition: all 0.1s ease-out;

          &:nth-last-of-type(2) {
            margin-inline-start: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            p {
              transition: all 0.5s ease-in-out;
              margin-inline-end: 5px;
            }

            &:hover {
              p {
                transform: translateX(0);
                opacity: 1;
              }
            }
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }

    &:hover,
    &.active {
      .footer {
        opacity: 1;
        z-index: 5;
      }
    }
  }

  .interactions-container {
    position: absolute;
    inset: 0;
    z-index: 10;
    background: $interactions-container-bg;
    overflow-y: hidden;
    // padding-top: 30px;

    .interaction-wrapper {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      text-align: start;
      gap: 40px;
      $childsPadding: 15px 30px;
      overflow-y: auto;

      .question-container {
        padding: $childsPadding;
        position: sticky;
        top: 0;
        background: $interactions-container-bg;

        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: $action-item-color;
          border-bottom: 1px solid $action-item-color;
          font-size: 1rem;

          svg {
            margin-inline-end: 5px;
          }
        }
      }

      .actions-container {
        padding: $childsPadding;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height: 100%;
        gap: 50px;

        .action {
          @include centerChilds;
          cursor: pointer;
          width: 40%;
          text-align: center;
          transition: all 0.5s ease-in-out;
          color: white;
          font-size: 1.5rem;
          background: $action-bg;
          padding: 10px 20px;
          border-radius: 15px;
          svg {
            font-size: 0.8rem;
            margin-inline-end: 5px;
            display: none;
          }

          &.disabled {
            cursor: not-allowed;
            opacity: 0.75;
          }

          &:not(.disabled):hover {
            transform: scale(1.05);
          }
        }
      }

      .choose-characters {
        // background: red;
        position: relative;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        overflow-y: hidden;
        .action {
          cursor: pointer;
          text-align: center;
          width: 25%;
          // height: 100%;
          position: absolute;
          color: white;
          font-size: 1.5rem;
          transition: all 0.5s ease-in-out;
          &:first-of-type {
            z-index: 10;
            left: 30%;
            top: 30%;
            height: 80%;
          }
          &:nth-of-type(2) {
            z-index: 10;
            left: 64%;
            top: 30%;
            height: 80%;
          }

          svg {
            display: none;
          }

          &:not(.disabled):hover {
            transform: scale(1.05);
          }
          &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }
    }

    footer {
      margin-top: auto;
      padding: 10px 20px;
      @include centerChilds;
      justify-content: flex-end;
      position: sticky;
      bottom: 0;
      background: $interactions-container-bg;

      p {
        cursor: pointer;
        background: $skip-item-bg;
        padding: 0px 15px;
        border-radius: 15px;
        color: $skip-item-color;
      }
    }
  }

  .image-preview {
    position: absolute;
    inset: 0;
    z-index: 10;

    div {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 5px;
      cursor: pointer;
      background: $skip-item-bg;
      color: $skip-item-color;
      @include centerChilds;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .aside-container {
    background: $aside-container-bg;
    color: $aside-container-color;
    position: absolute;
    top: 0;
    bottom: 40px;
    right: -500px;
    z-index: -50;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    width: 25%;
    max-width: 350px;
    min-width: 300px;
    overflow-y: auto;

    &.active {
      z-index: 10000;
      opacity: 1;
      right: 0;
    }
  }
}
