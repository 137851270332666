@keyframes fadeOut {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}

@mixin ellipsisText($numberOfLines: 1) {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
  -webkit-box-orient: vertical;
}

@mixin centerChilds {
  display: flex;
  justify-content: center;
  align-items: center;
}

$form-error-color: red;
$form-bg-color: rgba(255, 255, 255, 0.6);

$aside-container-bg: rgba(33, 33, 33, 0.85);
$aside-container-color: white;

$aside-header-background: white;
$aside-header-text-color: #121212;

$thumbnail-duration-text-color: white;
$thumbnail-duration-bg-color: #121212;

$section-active: #12121245;

$video-control-background: rgb(33 33 33 / 85%);
$video-control-svg-color: white;

$interactions-container-bg: rgba(0, 0, 0, 0.25);
$action-item-color: #121212;
$skip-item-bg: rgba(0, 0, 0, 0.479);
$skip-item-color: white;
