@import "./shared";

.App {
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;

  .home-video-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &>h3 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  .form {
    background:$form-error-color ;
    background-color: $form-bg-color;
    border-radius: 10px;
  }

  .logo {
    position: fixed;
    top: 20px;
    right: 20px;
    margin: 0;
    height: 70px;
  }

  .big-title {
    text-transform: capitalize;
    text-align: center;
    font-size: 2rem;
    padding: 0 15px;
  }

  .language-selector {
    position: fixed;
    top: 20px;
    left: 20px;
    margin: 0;
    /* height: 40px; */
    padding: 16px;
  }

  .select-lang-page {
    display: flex;
    width: 50vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .select-lang-page .language-selector {
    position: relative;
    top: 0;
    left: 0;
  }

  .select-lang-page .language-selector p {
    display: block;
  }
}
