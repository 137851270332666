@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
@import "./shared";
@import "./aside";
@import "./home";
@import "./interactive-video";
@import "./login";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    color: #45505b !important;
    font-family: "Cairo", sans-serif;
  }

:root {
  font-size: 18px;
}

/* Customize Scroll bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: 0.5s;
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
