@import "./shared";
.aside-container {
    h3 {
      font-weight: bold;
      padding: 10px 5px;
      background: $aside-header-background;
      color:$aside-header-text-color ;
      text-align: center;
      margin-bottom: 10px;
      font-size: 1.5rem;
      // position: sticky;
      // top: 0;
      // z-index: 5;
    }
    .section-container {
      display: flex;
      flex-direction: column;
      transition: all 0.5s ease-in-out;
      .section {
        cursor: pointer;
        display: flex;
        gap: 10px;
        padding: 5px 10px;
        transition: all 0.5s ease-in-out;
  
        .image-container {
          position: relative;
          max-width: 100px;
          overflow: hidden;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
          }
          span {
            position: absolute;
            color: $thumbnail-duration-text-color;
            font-weight: bold;
            right: 3px;
            bottom: 3px;
            font-size: 12px;
            background: $thumbnail-duration-bg-color;
            padding: 0px 5px;
            border-radius: 4px;
          }
        }
        p {
          max-height: 60px;
          font-size: 14px;
          @include ellipsisText(2);
          overflow: hidden;
          
        }
        &.active,
        &:hover {
          background: $section-active;
          border-radius: 4px;
        }
     
      
      }
    }
  }